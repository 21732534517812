//Home
import HomeCarousel1 from './assets/Audiovisual para contenido/ENMICE carrusel principal.webp';
import HomeCarousel2 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-2.webp';
import HomeCarousel3 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-3.webp';
import HomeCarousel4 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-4.webp';
import HomeCarousel5 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-5.webp';
import HomeCarousel6 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-6.webp';
import HomeCarousel7 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-7.webp';
import HomeCarousel8 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-8.webp';
import HomeCarousel9 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-9.webp';
import HomeCarousel10 from './assets/Audiovisual para contenido/ENMICE-carrusel-principal-10.webp';
import ENMICELogo from './assets/Logotipos/ENMICE/Imagotipo ENMICE circular BC.webp';
import ENMICE2021Map from './assets/ENMICE-desarrollo-coheteria-nivel-nacional-2021.webp';
import ENMICE2022Map from './assets/ENMICE-desarrollo-coheteria-nivel-nacional-2022.webp';
import ENMICE20232024Map from './assets/ENMICE-desarrollo-coheteria-nivel-nacional-2023-2024.webp';

//Staff Images
import AboutUsFelipeAvilaImage from './assets/Fotos de equipo ENMICE/Staff/Felipe-Avila.webp';
import AboutUsWalterAhrens from './assets/Fotos de equipo ENMICE/Staff/Walter-Ahrens-ENMICE.webp';
import AboutUsAngelVazquezImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Angel-Velazquez.webp';
import AboutUsArielGomezImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Ariel-Gomez.webp';
import AboutUsMaiteCUImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Maité-CU.webp';
import AboutUsMalcomPinedaImage from './assets/Fotos de equipo ENMICE/Staff/FotoMalcom.webp';
import AboutUsPaulinaAguayoImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Paulina-Aguayo.webp';
import AboutUsRamonCordovaImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Ramon-Córdova.webp';
import AboutUsRodrigoMorenoImage from './assets/Fotos de equipo ENMICE/Staff/FotoRodrigoMoreno.webp';
import AboutUsRossyDelgadoImage from './assets/Fotos de equipo ENMICE/Staff/FotoRossyDelgadoOrtiz.webp';
import AboutUsXimenaVelascoImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Ximena-Velasco.webp';
import AboutUsPatyLomeliImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Paty-Lomelí.webp';
import AboutUsPaulinaCabreraImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Paulina-Cabrera.webp';
import AboutUsAnaelUlailaImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Anael-Ulaila-web.webp';
import AboutUsBraulioAlvarezImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Braulio-Alvarez.webp';
import AboutUsGodricAcevesImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Godric-Aceves.webp';
import AboutUsJofielGarciaImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Jofiel-Garcia.webp';
import AboutUsBruceImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Bruce.webp';
import AboutUsOmarSanchezImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Omar-Sanchez.webp';
import AboutUsAntonioRodriguezImage from './assets/Fotos de equipo ENMICE/Staff/Foto-Antonio-Rodriguez.webp';
import AboutUsMarcoGutierrezImage from './assets/Fotos de equipo ENMICE/Staff/FotoMarcoGutierrez.webp';
import AboutUsHugoGalvanImage from './assets/Fotos de equipo ENMICE/Staff/FotoHugoGalvan.webp';
import AboutUsAlexaPadillaImage from './assets/Fotos de equipo ENMICE/Staff/FotoAlexaPadilla.webp';
import AboutUsSarahMilletImage from './assets/Fotos de equipo ENMICE/Staff/SarahMilletFoto.webp';
import AboutUsKarlaErandeniImage from './assets/Fotos de equipo ENMICE/Staff/FotoKarlaErandeni.webp';
import AboutUsAlejandroCendejasImage from './assets/Fotos de equipo ENMICE/Staff/FotoAlejandroCendejas.webp';

//Campus Chief Images
import AboutUsGabrielBasilioImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Gabriel Basilio.webp';
import AboutUsJoseAndresImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto José Andrés.webp';
import AboutUsKarlaDiazImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Karla Díaz.webp';
import AboutUsLeonelImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Leonel.webp';
import AboutUsMariaFernandaImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Maria Fernanda.webp';
import AboutUsMtroJesusImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Mtro Jesus.webp';
import AboutUsValeriaCurielImage from './assets/Fotos de equipo ENMICE/Campus Chief/Foto Valeria Curiel.webp';

//ENMICE 2021
import ENMICE202101 from './assets/Audiovisual para contenido/ENMICE 2021/20210529_100605.webp';
import ENMICE202102 from './assets/Audiovisual para contenido/ENMICE 2021/20210529_112235_2.webp';
import ENMICE202103 from './assets/Audiovisual para contenido/ENMICE 2021/20210530_135156_HDR_2.webp';
import ENMICE202104 from './assets/Audiovisual para contenido/ENMICE 2021/ENMICE-2021-1.webp';
import ENMICE202105 from './assets/Audiovisual para contenido/ENMICE 2021/ENMICE-2021-2.webp';
import ENMICE202106 from './assets/Audiovisual para contenido/ENMICE 2021/ENMICE-2021-4.webp';
import ENMICE202107 from './assets/Audiovisual para contenido/ENMICE 2021/ENMICE 2022 foto 10.webp';
import ENMICE202108 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_0575.webp';
import ENMICE202109 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_0938.webp';
import ENMICE202110 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_1132.webp';
import ENMICE202111 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_1815.webp';
import ENMICE202112 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_4138.webp';
import ENMICE202113 from './assets/Audiovisual para contenido/ENMICE 2021/IMG_9865.webp';
import ENMICE202114 from './assets/Audiovisual para contenido/ENMICE 2021/IMG-20210529-WA0043.webp';
import ENMICE202115 from './assets/Audiovisual para contenido/ENMICE 2021/IMG-20210529-WA0074.webp';
import ENMICE202116 from './assets/Audiovisual para contenido/ENMICE 2021/Screenshot 2021-06-01 111527.webp';
import Logo2021 from './assets/Logotipos/ENMICE/Imagotipo ENMICE2021.webp';
import Flyer2021 from './assets/Audiovisual para contenido/ENMICE 2021/Flyer-ENMICE-2021.webp';

//ENMICE 2022
import ENMICE202201 from './assets/Audiovisual para contenido/ENMICE 2022/1E36866A-7805-4B0A-89B3-2A1A4F38957E.webp';
import ENMICE202202 from './assets/Audiovisual para contenido/ENMICE 2022/247F4347-8587-404A-A574-17585360BBEF.webp';
import ENMICE202203 from './assets/Audiovisual para contenido/ENMICE 2022/Carrusel-ENMICE-2022_1.webp';
import ENMICE202204 from './assets/Audiovisual para contenido/ENMICE 2022/Carrusel-ENMICE-2022_8.webp';
import ENMICE202205 from './assets/Audiovisual para contenido/ENMICE 2022/Cohete kondakova.webp';
import ENMICE202206 from './assets/Audiovisual para contenido/ENMICE 2022/DSC_6918.webp';
import ENMICE202207 from './assets/Audiovisual para contenido/ENMICE 2022/DSC07222.ARW';
import ENMICE202208 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE 2022 foto 09.webp';
import ENMICE202209 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE 2022 foto.webp';
import ENMICE202210 from './assets/Audiovisual para contenido/ENMICE 2022/Foto general.webp';
import ENMICE202211 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE-2022-1.webp';
import ENMICE202212 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2444.HEIC';
import ENMICE202213 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2471.webp';
import ENMICE202214 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2476.webp';
import ENMICE202215 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_2513 2.webp';
import ENMICE202216 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4020.webp';
import ENMICE202217 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4021.webp';
import ENMICE202218 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_4038.webp';
import ENMICE202219 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7136.webp';
import ENMICE202220 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7215.webp';
import ENMICE202221 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7216.HEIC';
import ENMICE202222 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7254.HEIC';
import ENMICE202223 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7285.webp';
import ENMICE202224 from './assets/Audiovisual para contenido/ENMICE 2022/IMG_7308.webp';
import ENMICE202225 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE-2022-2.webp';
import ENMICE202226 from './assets/Audiovisual para contenido/ENMICE 2022/ENMICE-2022-3.webp';
import ENMICE202227 from './assets/Audiovisual para contenido/ENMICE 2022/IMG-20220926-WA0091.webp';
import Logo2022 from './assets/Logotipos/ENMICE/Imagotipo ENMICE2022-02.webp';
import Flyer2022 from './assets/Audiovisual para contenido/ENMICE 2022/Flyer-ENMICE-2022.webp';

//ENMICE 2023
import ENMICE202301 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_DSC1902.webp';
import ENMICE202302 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_DSC6025.webp';
import ENMICE202303 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_MG_4561.webp';
import ENMICE202304 from './assets/Audiovisual para contenido/ENMICE 2023-2024/_MG_4697.webp';
import ENMICE202305 from './assets/Audiovisual para contenido/ENMICE 2023-2024/ENMICE 2023-2024.webp';
import ENMICE202306 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0109.webp';
import ENMICE202307 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0127.webp';
import ENMICE202308 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0191.webp';
import ENMICE202309 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0300.webp';
import ENMICE202310 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_0354.webp';
import ENMICE202311 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_7986.webp';
import ENMICE202312 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_9999.webp';
import ENMICE202313 from './assets/Audiovisual para contenido/ENMICE 2023-2024/IMG_20240408_143253.webp';
import FlyerGuerrero from './assets/Audiovisual para contenido/ENMICE 2023-2024/Poster-para-redes-ENMICE-2023.webp';
import FlyerChihuahua from './assets/Audiovisual para contenido/ENMICE 2023-2024/POSTERS-2023-2024-web.webp';

//ENMICE 2025
import ENMICE202501 from './assets/Audiovisual para contenido/ENMICE2025/Conviertete-en-patrocinador.webp';
import ENMICE202502 from './assets/Audiovisual para contenido/ENMICE2025/Dos-días-de-lanzamientos.webp';
import ENMICE202503 from './assets/Audiovisual para contenido/ENMICE2025/ENMICE-es-una-marca-de-Explora.webp';
import ENMICE202504 from './assets/Audiovisual para contenido/ENMICE2025/Exposiciones-en-stands.webp';
import ENMICE202505 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-2.webp';
import ENMICE202506 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-3.webp';
import ENMICE202507 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-4.webp';
import ENMICE202508 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-5.webp';
import ENMICE202509 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-6.webp';
import ENMICE202510 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-7.webp';
import ENMICE202511 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background-8.webp';
import ENMICE202512 from './assets/Audiovisual para contenido/ENMICE2025/Fotos-2025-carrusel-background.webp';
import ENMICE202513 from './assets/Audiovisual para contenido/ENMICE2025/Registro-20-de-diciembre.webp';
import ENMICE202514 from './assets/Audiovisual para contenido/ENMICE2025/Laguna-de-Sayula-01.webp';
import ENMICE202515 from './assets/Audiovisual para contenido/ENMICE2025/Laguna-de-Sayula-02.webp';
import ENMICE202516 from './assets/Audiovisual para contenido/ENMICE2025/Laguna-de-Sayula-03.webp';
import ENMICE202517 from './assets/Audiovisual para contenido/ENMICE2025/Lanzamiento.webp';
import ENMICE202518 from './assets/Audiovisual para contenido/ENMICE2025/Página-web-Ediciones-anteriores-01.webp';
import ENMICE202519 from './assets/Audiovisual para contenido/ENMICE2025/Página-web-Ediciones-anteriores-02.webp';
import ENMICE202520 from './assets/Audiovisual para contenido/ENMICE2025/Página-web-Ediciones-anteriores-03.webp';
import ENMICE202521 from './assets/Audiovisual para contenido/ENMICE2025/Senado-ENMICE-01.webp';
import ENMICE202522 from './assets/Audiovisual para contenido/ENMICE2025/Zapopan-01.webp';
import ENMICE202523 from './assets/Audiovisual para contenido/ENMICE2025/Zapopan-02.webp';
import ENMICE202524 from './assets/Audiovisual para contenido/ENMICE2025/Zapopan-03.webp';
import ENMICE202525 from './assets/Audiovisual para contenido/ENMICE2025/Logo2025ENMICE.webp';
import ENMICE202526 from './assets/Audiovisual para contenido/ENMICE2025/Modalidad-Competencia.webp';
import ENMICE202527 from './assets/Audiovisual para contenido/ENMICE2025/Modalidad-Exhibición.webp';
import ENMICE202528 from './assets/Audiovisual para contenido/ENMICE2025/Modalidad-Patrocinador.webp';
import ENMICE202529 from './assets/Audiovisual para contenido/ENMICE2025/Modalidad-Publico-General.webp';
import ENMICE202530 from './assets/Audiovisual para contenido/ENMICE2025/Modalidad-Voluntario-a.webp';
import ENMICE202531 from './assets/Audiovisual para contenido/ENMICE2025/rocket-launch-693214_1920.webp';

//Academia
import AcademiaENMICELogo from './assets/Logotipos/ENMICE/Academia ENMICE logo_Mesa de trabajo 1.webp';
import AcademiaENMICEOnePager from './assets/Academia ENMICE onepage 2024-2025.webp';
import AcademiaCocytenbLogo from './assets/Logotipos/Academia ENMICE/COCYTEN-04.webp';
import AcademiaSharevoltsLogo from './assets/Logotipos/Academia ENMICE/Logo-ShareVolts-01-1.webp';
import AcademiaMuseoInteractivoLogo from './assets/Logotipos/Academia ENMICE/Museo Interactivo - Tinto vertical.webp';
import AcademiaUCEMICHLogo from './assets/Logotipos/Academia ENMICE/UCEMICH Color logo.webp';
import AcademiaUniversidadTecMilenioLogo from './assets/Logotipos/Academia ENMICE/Universidad Tec Milenio.webp';
import AcademiaCUTLogo from './assets/Logotipos/Academia ENMICE/cut.webp';
import AcademiaVikingosLogo from './assets/Logotipos/Academia ENMICE/Vikingos Espaciales color.webp';
import AcademiaCarousel1 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel.webp';
import AcademiaCarousel2 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-ENMICE-carrusel-principal.webp';
import AcademiaCarousel3 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-ENMICE-carrusel-principal-3.webp';
import AcademiaCarousel4 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-ENMICE-carrusel-principal-5.webp';
import AcademiaCarousel5 from './assets/Audiovisual para contenido/Academia ENMICE/ACADEMIA-carrusel-5.webp';
import Academia1 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_3611.HEIC';
import Academia2 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7022.HEIC';
import Academia3 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7025.HEIC';
import Academia4 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_7031.HEIC';
import Academia5 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_8904.HEIC';
import Academia6 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_8906.HEIC';
import Academia7 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9230.webp';
import Academia8 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9231.webp';
import Academia9 from './assets/Audiovisual para contenido/Academia ENMICE/IMG_9233.webp';
import CursoArqueología from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/Postal-Arqueologia-y-Antropologia.webp';
import CursoOpenRocket from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/Open-Rocket-Academia-ENMICE-03.webp';
import CursoPCB from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/Diseno-PCB-Academia-ENMICE-07.webp';
import Curso202501 from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/FlyerRecuperacionWeb.webp';
import Curso202502 from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/ENMICE-On-Demand-01.webp';
import Curso202503 from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/ENMICE-On-Demand-02.webp';
import Curso202504 from './assets/Audiovisual para contenido/Academia ENMICE/Contenido Academia ENMICE/FinanzasProyectosAeroespacialesWeb.webp';

//Allies
//Sponsors
import SkarchLogo from './assets/Logotipos/Patrocinadores/Agua-Skarch-logo-blanco.webp';
import AnsysLogo from './assets/Logotipos/Patrocinadores/Ansys-logo-blanco.webp';
import AXONLogo from './assets/Logotipos/Patrocinadores/AXON_cables-connectors-logo-blanco.webp';
import AutoTokioNissanLogo from './assets/Logotipos/Patrocinadores/Auto-Tokio-Nissan-logo-blanco.webp';
import Bid360Logo from './assets/Logotipos/Patrocinadores/Bid-360-logo-blanco.webp';
import CraftAviaLogo from './assets/Logotipos/Patrocinadores/CRAFT-AVIA-LOGO-BLANCO.webp';
import DaviLogo from './assets/Logotipos/Patrocinadores/Davi-logo-blanco.webp';
import GrupoSSCLogo from './assets/Logotipos/Patrocinadores/Grupo-SSC-logo-blanco.webp';
import DewesoftLogo from './assets/Logotipos/Patrocinadores/Logo-Dewesoft-blanco.webp';
import ERAAviacionLogo from './assets/Logotipos/Patrocinadores/Logo-ERA-Aviacion-blanco.webp';
import HoneywellAerospaceTechnologiesLogo from './assets/Logotipos/Patrocinadores/Logo-Honeywell-Aerospace-Technologies-blanco.webp';
import NoMADALogo from './assets/Logotipos/Patrocinadores/Logo-NoMADA-blanco.webp';
import SharevoltsLogo from './assets/Logotipos/Patrocinadores/Logo-Sharevolts-2.webp';
import MarDSalLogo from './assets/Logotipos/Patrocinadores/Mar-d_Sal-logo-blanco.webp';
import RedColaLogo from './assets/Logotipos/Patrocinadores/Red-Cola-logo-blanco.webp';
import SidralAgaLogo from './assets/Logotipos/Patrocinadores/Sidral-Aga-logo-balnco.webp';
import SterenLogo from './assets/Logotipos/Patrocinadores/Steren-Logotipo-blanco.webp';
import ThrustersUnlimitedLogo from './assets/Logotipos/Patrocinadores/Thrusters-Unlimited-blanco-logo.webp';
import UMGLogo from './assets/Logotipos/Patrocinadores/UMG-LOGO-WEB.webp';

//Collaborators
import VivevolandoLogo from './assets/Logotipos/Colaboradores/Academia-Aeronautica-Vivevolando-logo-blanco.webp';
import AhChihuahuaLogo from './assets/Logotipos/Colaboradores/Ah-Chihuahua-logo.webp';
import ALCELogo from './assets/Logotipos/Colaboradores/ALCE-logo-blanco.webp';
import BuroDeConvencionesChihuahuaLogo from './assets/Logotipos/Colaboradores/Buro-de-convenciones-Chihuahua-logo-blanco.webp';
import CamaraDeDiputadosLogo from './assets/Logotipos/Colaboradores/Camara-de-diputados-logo-blanco.webp';
import CenaltecLogo from './assets/Logotipos/Colaboradores/Cenaltec-logo-blanco.webp';
import ChihuahuaCapitalLogo from './assets/Logotipos/Colaboradores/Chihuahua-Capital-Logo-blanco.webp';
import ChihuahuaAerospaceClusterLogo from './assets/Logotipos/Colaboradores/Chihuahua_s-Aerospace-Cluster-logo-blanco.webp';
import CIMAVLogo from './assets/Logotipos/Colaboradores/CIMAV-logo-blanco.webp';
import ConsejoAeroespacialDeJaliscoLogo from './assets/Logotipos/Colaboradores/Consejo-Aeroespacial-de-Jalisco-logo-blanco.webp';
import CUCEALogo from './assets/Logotipos/Colaboradores/CUCEA-logo-blanco.webp';
import CutonalaLogo from './assets/Logotipos/Colaboradores/cutonala_logo_blanco.webp';
import ENAHLogo from './assets/Logotipos/Colaboradores/ENAH-logo-blanco.webp';
import UACHLogo from './assets/Logotipos/Colaboradores/escudo-horizontal-texto-blanco-UACH_logo.webp';
import SecretariaEconomiaLogo from './assets/Logotipos/Colaboradores/SE2024-logo-blanco.webp';
import FacultadDeIngenieriaLogoBlanco from './assets/Logotipos/Colaboradores/Facultad-de-Ingenieria-Logo-Blanco.webp';
import FAULogo from './assets/Logotipos/Colaboradores/FAU-logo-grises.webp';
import FEMIALogo from './assets/Logotipos/Colaboradores/FEMIA-espanol-blanco.webp';
import GOBSayulaLogo from './assets/Logotipos/Colaboradores/GOB-SAYULA-LOGO-BLANCO.webp';
import GobiernoMunicipalChihuahuaLogo from './assets/Logotipos/Colaboradores/Gobierno-Municipal-Chihuahua-logo-blanco.webp';
import IndexLogo from './assets/Logotipos/Colaboradores/Index-logo-blanco.webp';
import Explora from './assets/Logotipos/Colaboradores/Logo-blanco-Explora.webp';
import CUCEILogo from './assets/Logotipos/Colaboradores/Logo-CUCEI-blanco-2024.webp';
import AVAMLogo from './assets/Logotipos/Colaboradores/Logos-AVAM_page-0008.webp';
import HackerLogo from './assets/Logotipos/Colaboradores/Logo-Hacker-blanco-sin-fondo-(1).webp';
import LSATLogo from './assets/Logotipos/Colaboradores/Logo-LSAT-blanco.webp';
import FAMEXLogo from './assets/Logotipos/Colaboradores/logofamexblanco2023.webp';
import MiNDIndustrialesLogo from './assets/Logotipos/Colaboradores/MiND-Industriales-PRO-logo-blanco.webp';
import NASAGoddardLogo from './assets/Logotipos/Colaboradores/NASA-Goddard-logo-balnco.webp';
import RaymixFundacionLogo from './assets/Logotipos/Colaboradores/Raymix-Fundacion-logo-blanco.webp';
import SCTAEMLogo from './assets/Logotipos/Colaboradores/SCT-AEM-logo-blanco.webp';
import SemanaMundialDelEspacioLogo from './assets/Logotipos/Colaboradores/Semana-Mundial-del-Espacio-Mexico.webp';
import SIEMENSLogo from './assets/Logotipos/Colaboradores/sie-logo-white-rgb.webp';
import SociedadAstronomicaChihuahuaLogo from './assets/Logotipos/Colaboradores/SOCIEDAD-ASTRONOMICA-CHIHUAHUA-LOGO.webp';
import SpaceCenterHoustonLogo from './assets/Logotipos/Colaboradores/Space-Center-Houston-logo-blanco.webp';
import SpaceGenerationAdvisoryCouncilLogo from './assets/Logotipos/Colaboradores/Space-Generation-Advisory-Council-logo-blanco.webp';
import ThrustersUnlimitedBlancoLogo from './assets/Logotipos/Colaboradores/Thrusters-Unlimited-blanco-logo.webp';
import TLOscurosLogo from './assets/Logotipos/Colaboradores/TL-Oscuros.webp';
import UDEGLogo from './assets/Logotipos/Colaboradores/UdeG-logo-blanco.webp';
import WorldSpaceWeekLogo from './assets/Logotipos/Colaboradores/World-Space-Week-02.webp';
//import { Home } from '@mui/icons-material';

//Campus Chief
import CampusChief01 from './assets/Audiovisual para contenido/Campus Chief/430327623_944835407433411_5062058506789620091_n.webp';
import CampusChief02 from './assets/Audiovisual para contenido/Campus Chief/432426466_944820367434915_6220519481564049029_n.webp';
import CampusChief03 from './assets/Audiovisual para contenido/Campus Chief/432453350_944783400771945_5881835689124556581_n.webp';
import CampusChief04 from './assets/Audiovisual para contenido/Campus Chief/b35f2d12-490e-469a-afb6-8921b589e797.webp';
import CampusChief05 from './assets/Audiovisual para contenido/Campus Chief/CAMPUS-CHIEF-ENMICE-carrusel-principal-2.webp';
import CampusChief06 from './assets/Audiovisual para contenido/Campus Chief/CAMPUS-CHIEF-ENMICE-carrusel-principal-3.webp';
import CampusChief07 from './assets/Audiovisual para contenido/Campus Chief/CAMPUS-CHIEF-ENMICE-carrusel-principal-4.webp';
import CampusChief08 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-5.webp';
import CampusChief09 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-6.webp';
import CampusChief10 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel-7.webp';
import CampusChief11 from './assets/Audiovisual para contenido/Campus Chief/Campus-Chief-carrusel.webp';
import CampusChief12 from './assets/Audiovisual para contenido/Campus Chief/Copia de 20240307_133405.webp';
import CampusChief13 from './assets/Audiovisual para contenido/Campus Chief/d4302450-fb0b-44d5-b7ed-35eb1a244dc5.webp';
import CampusChief14 from './assets/Audiovisual para contenido/Campus Chief/Dewesoft 4.webp';
import CampusChief15 from './assets/Audiovisual para contenido/Campus Chief/IMG_1723.HEIC';
import CampusChief16 from './assets/Audiovisual para contenido/Campus Chief/IMG_8825.HEIC';
import CampusChief17 from './assets/Audiovisual para contenido/Campus Chief/WhatsApp Image 2024-03-09 at 8.47.09 PM.webp';
import CampusChief18 from './assets/Audiovisual para contenido/Campus Chief/WhatsApp Image 2024-03-12 at 17.34.31 (1).webp';
import CampusChiefLogo from './assets/Logotipos/ENMICE/ENMICE Campus Chief logo.webp';
import CampusChiefConalepLogo from './assets/Logotipos/Campus Chief/Conalep Sonora.webp';
import CampusChiefCUCEALogo from './assets/Logotipos/Campus Chief/CUCEA logo colores.webp';
import CampusChiefCUCEILogo from './assets/Logotipos/Campus Chief/cucei-logo 2.webp';
import CampusChiefCUTLogo from './assets/Logotipos/Campus Chief/CUTonala logo.webp';
import CampusChiefIberoLogo from './assets/Logotipos/Campus Chief/Ibero CDMX.webp';
import CampusChiefIPNLogo from './assets/Logotipos/Campus Chief/IPN logo guinda.webp';
import CampusChiefIASALogo from './assets/Logotipos/Campus Chief/Logo IASA.webp';
import CampusChiefMaristaLogo from './assets/Logotipos/Campus Chief/Pagina web logos-04.webp';
import CampusChiefUDGLogo from './assets/Logotipos/Campus Chief/UdeG logo png.webp';
import CampusChiefUNAMLogo from './assets/Logotipos/Campus Chief/unam-escudo-azul.webp';
import CampusChiefUTNLogo from './assets/Logotipos/Campus Chief/UTN Logo.webp';
import CampusChiefAragonsLogo from './assets/Logotipos/Campus Chief/Patrocinadores Campus Chief/Aragons publicidad.webp';
import CampusChiefRedColaLogo from './assets/Logotipos/Campus Chief/Patrocinadores Campus Chief/Red Cola.webp';
import CampusChiefSidralLogo from './assets/Logotipos/Campus Chief/Patrocinadores Campus Chief/Sidral Aga.webp';
import CampusChiefSkarchLogo from './assets/Logotipos/Campus Chief/Patrocinadores Campus Chief/Skarch_logotipo.webp';
import CampusChiefSterenLogo from './assets/Logotipos/Campus Chief/Patrocinadores Campus Chief/Steren.webp';
import CampusChiefSemanaMundial from './assets/Audiovisual para contenido/Campus Chief/Posters-Mtro-Jesus-Abraham-2024B-02.webp';
import CampusChiefUNAM from './assets/Audiovisual para contenido/Campus Chief/Postales-WSW-UNAM-2024-02.webp';
import CampusChiefSemanaMundial2 from './assets/Audiovisual para contenido/Campus Chief/Semana-del-espacio-ENMICE-Campus-Chief-Yucatan.webp';
import CampusChiefENAH from './assets/Audiovisual para contenido/Campus Chief/ENMICE-Campus-Chief-ENAH-Lic-Daniela-Calva.webp';
import CampusChiefUTN from './assets/Audiovisual para contenido/Campus Chief/ENMICE-Campus-Chief-Manuel-Orozco-UTN.webp';

//Store



const imageIndex = {
    //Home
    HomeCarousel1,
    HomeCarousel2,
    HomeCarousel3,
    HomeCarousel4,
    HomeCarousel5,
    HomeCarousel6,
    HomeCarousel7,
    HomeCarousel8,
    HomeCarousel9,
    HomeCarousel10,
    ENMICELogo,
    ENMICE2021Map,
    ENMICE2022Map,
    ENMICE20232024Map,

    //Staff Images
    AboutUsFelipeAvilaImage,
    AboutUsWalterAhrens,
    AboutUsAngelVazquezImage,
    AboutUsArielGomezImage,
    AboutUsMaiteCUImage,
    AboutUsMalcomPinedaImage,
    AboutUsPaulinaAguayoImage,
    AboutUsRamonCordovaImage,
    AboutUsRodrigoMorenoImage,
    AboutUsRossyDelgadoImage,
    AboutUsXimenaVelascoImage,
    AboutUsPatyLomeliImage,
    AboutUsPaulinaCabreraImage,
    AboutUsAnaelUlailaImage,
    AboutUsBraulioAlvarezImage,
    AboutUsGodricAcevesImage,
    AboutUsJofielGarciaImage,
    AboutUsBruceImage,
    AboutUsOmarSanchezImage,
    AboutUsAntonioRodriguezImage,
    AboutUsMarcoGutierrezImage,
    AboutUsHugoGalvanImage,
    AboutUsAlexaPadillaImage,
    AboutUsSarahMilletImage,
    AboutUsKarlaErandeniImage,
    AboutUsAlejandroCendejasImage,

    //Campus Chief Images
    AboutUsGabrielBasilioImage,
    AboutUsJoseAndresImage,
    AboutUsKarlaDiazImage,
    AboutUsLeonelImage,
    AboutUsMariaFernandaImage,
    AboutUsMtroJesusImage,
    AboutUsValeriaCurielImage,

    //ENMICE 2021
    ENMICE202101,
    ENMICE202102,
    ENMICE202103,
    ENMICE202104,
    ENMICE202105,
    ENMICE202106,
    ENMICE202107,
    ENMICE202108,
    ENMICE202109,
    ENMICE202110,
    ENMICE202111,
    ENMICE202112,
    ENMICE202113,
    ENMICE202114,
    ENMICE202115,
    ENMICE202116,
    Logo2021,
    Flyer2021,

    //ENMICE 2022
    ENMICE202201,
    ENMICE202202,
    ENMICE202203,
    ENMICE202204,
    ENMICE202205,
    ENMICE202206,
    ENMICE202207,
    ENMICE202208,
    ENMICE202209,
    ENMICE202210,
    ENMICE202211,
    ENMICE202212,
    ENMICE202213,
    ENMICE202214,
    ENMICE202215,
    ENMICE202216,
    ENMICE202217,
    ENMICE202218,
    ENMICE202219,
    ENMICE202220,
    ENMICE202221,
    ENMICE202222,
    ENMICE202223,
    ENMICE202224,
    ENMICE202225,
    ENMICE202226,
    ENMICE202227,
    Logo2022,
    Flyer2022,

    //ENMICE 2023
    ENMICE202301,
    ENMICE202302,
    ENMICE202303,
    ENMICE202304,
    ENMICE202305,
    ENMICE202306,
    ENMICE202307,
    ENMICE202308,
    ENMICE202309,
    ENMICE202310,
    ENMICE202311,
    ENMICE202312,
    ENMICE202313,
    FlyerGuerrero,
    FlyerChihuahua,

    //ENMICE 2025
    ENMICE202501,
    ENMICE202502,
    ENMICE202503,
    ENMICE202504,
    ENMICE202505,
    ENMICE202506,
    ENMICE202507,
    ENMICE202508,
    ENMICE202509,
    ENMICE202510,
    ENMICE202511,
    ENMICE202512,
    ENMICE202513,
    ENMICE202514,
    ENMICE202515,
    ENMICE202516,
    ENMICE202517,
    ENMICE202518,
    ENMICE202519,
    ENMICE202520,
    ENMICE202521,
    ENMICE202522,
    ENMICE202523,
    ENMICE202524,
    ENMICE202525,
    ENMICE202526,
    ENMICE202527,
    ENMICE202528,
    ENMICE202529,
    ENMICE202530,
    ENMICE202531,

    //Academia
    AcademiaENMICELogo,
    AcademiaENMICEOnePager,
    AcademiaCocytenbLogo,
    AcademiaSharevoltsLogo,
    AcademiaMuseoInteractivoLogo,
    AcademiaUCEMICHLogo,
    AcademiaUniversidadTecMilenioLogo,
    AcademiaCUTLogo,
    AcademiaVikingosLogo,
    AcademiaCarousel1,
    AcademiaCarousel2,
    AcademiaCarousel3,
    AcademiaCarousel4,
    AcademiaCarousel5,
    Academia1,
    Academia2,
    Academia3,
    Academia4,
    Academia5,
    Academia6,
    Academia7,
    Academia8,
    Academia9,
    CursoArqueología,
    CursoOpenRocket,
    CursoPCB,
    Curso202501,
    Curso202502,
    Curso202503,
    Curso202504,

    //Sponsors
    SkarchLogo,
    AnsysLogo,
    AutoTokioNissanLogo,
    AXONLogo,
    Bid360Logo,
    CraftAviaLogo,
    DaviLogo,
    GrupoSSCLogo,
    DewesoftLogo,
    ERAAviacionLogo,
    HoneywellAerospaceTechnologiesLogo,
    NoMADALogo,
    SharevoltsLogo,
    MarDSalLogo,
    RedColaLogo,
    SidralAgaLogo,
    SterenLogo,
    ThrustersUnlimitedLogo,
    UMGLogo,

    //Collaborators
    VivevolandoLogo,
    AhChihuahuaLogo,
    ALCELogo,
    BuroDeConvencionesChihuahuaLogo,
    CamaraDeDiputadosLogo,
    CenaltecLogo,
    ChihuahuaCapitalLogo,
    ChihuahuaAerospaceClusterLogo,
    CIMAVLogo,
    ConsejoAeroespacialDeJaliscoLogo,
    CUCEALogo,
    CutonalaLogo,
    ENAHLogo,
    UACHLogo,
    SecretariaEconomiaLogo,
    FacultadDeIngenieriaLogoBlanco,
    FAULogo,
    FEMIALogo,
    GOBSayulaLogo,
    GobiernoMunicipalChihuahuaLogo,
    IndexLogo,
    Explora,
    CUCEILogo,
    AVAMLogo,
    HackerLogo,
    LSATLogo,
    FAMEXLogo,
    MiNDIndustrialesLogo,
    NASAGoddardLogo,
    RaymixFundacionLogo,
    SCTAEMLogo,
    SemanaMundialDelEspacioLogo,
    SIEMENSLogo,
    SociedadAstronomicaChihuahuaLogo,
    SpaceCenterHoustonLogo,
    SpaceGenerationAdvisoryCouncilLogo,
    TLOscurosLogo,
    ThrustersUnlimitedBlancoLogo,
    UDEGLogo,
    WorldSpaceWeekLogo,

    //Campus Chief
    CampusChief01,
    CampusChief02,
    CampusChief03,
    CampusChief04,
    CampusChief05,
    CampusChief06,
    CampusChief07,
    CampusChief08,
    CampusChief09,
    CampusChief10,
    CampusChief11,
    CampusChief12,
    CampusChief13,
    CampusChief14,
    CampusChief15,
    CampusChief16,
    CampusChief17,
    CampusChief18,
    CampusChiefLogo,
    CampusChiefConalepLogo,
    CampusChiefCUCEALogo,
    CampusChiefCUCEILogo,
    CampusChiefCUTLogo,
    CampusChiefIberoLogo,
    CampusChiefIPNLogo,
    CampusChiefIASALogo,
    CampusChiefMaristaLogo,
    CampusChiefUDGLogo,
    CampusChiefUNAMLogo,
    CampusChiefUTNLogo,
    CampusChiefAragonsLogo,
    CampusChiefRedColaLogo,
    CampusChiefSidralLogo,
    CampusChiefSkarchLogo,
    CampusChiefSterenLogo,
    CampusChiefSemanaMundial,
    CampusChiefUNAM,
    CampusChiefSemanaMundial2,
    CampusChiefENAH,
    CampusChiefUTN,

    //Store

  };
  
  export default imageIndex;
